import TYPES from '@/types';

import Vue from 'vue';

// Application
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AccessMoreStrategySuccesfullyViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.access-more-strategy-successfully';

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  readonly view: Vue;

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.view = view;
    this.state = this.manager_service.getAccessMoreStrategiesState();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  accept = () => {
    this.view.$emit('accept');
  }
}
