



















































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import AccessMoreStrategySuccessfullyViewModel from
  '@/vue-app/view-models/allianz-dashboard/access-more-strategies/access-more-strategy-successfully-view-model';

@Component({
  name: 'AccessMoreStrategySuccessfullyDialog',
  filters: { currencyFormat },
})
export default class RequestsDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(new AccessMoreStrategySuccessfullyViewModel(this));

  accept() {
    this.synced_is_open = false;
    this.view_model.accept();
  }
}
